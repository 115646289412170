import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import EmailIcon from '@mui/icons-material/AlternateEmail';
import { Auth } from '@layouts';
import { LinkWithQuery } from '@components';
import useUser from '@hooks/useUser';
import useABTesting from 'hooks/useABTesting';

export default function ForgotPassword() {
  const { t } = useABTesting();
  const [email, setEmail] = useState('');
  const { session, forgotPassword } = useUser();
  const { isError, isSuccess, isLoading, error, mutate } = forgotPassword;

  useEffect(() => {
    if (session) {
      navigate('/');
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    mutate({ email });
  };

  return (
    <Auth component="main" maxWidth="xs">
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          color="primary"
          component="h1"
          align="left"
          variant="h4"
          sx={{ width: '100%', mb: 3 }}
        >
          {t('pages.forgot_password.title')}
        </Typography>
        <Box sx={{ mt: 3 }}>
          {isSuccess ? (
            <Typography align="center">
              {t('pages.forgot_password.success.text')}
            </Typography>
          ) : (
            <TextField
              required
              fullWidth
              id="email"
              placeholder={t('components.input.email.placeholder')}
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              error={isError}
              helperText={isError ? error.message : ''}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
          {isSuccess ? (
            <LinkWithQuery to="/sign-in">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {t('pages.forgot_password.success.button')}
              </Button>
            </LinkWithQuery>
          ) : (
            <>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={isLoading || isSuccess}
                onClick={handleSubmit}
                sx={{ mt: 3, mb: 2 }}
              >
                {t('pages.forgot_password.button')}
              </Button>
              <LinkWithQuery to="/sign-in">
                <Typography variant="body2" align="center">
                  {t('pages.forgot_password.return')}
                </Typography>
              </LinkWithQuery>
            </>
          )}
        </Box>
      </Box>
    </Auth>
  );
}
